import globalState, { addGlobalState } from './globalState';

export function consumeItem(item_name) {
  const current = getCurrentItemQuantity(item_name);
  if (current > 0) {
    addToInventory(item_name, -1);
    return true;
  }

  return false;
}

export function getCurrentItemQuantity(item_name) {
  return globalState.get(['inventory', item_name]) || 0;
}

export function addToInventory(item_name, quantity = 1) {
  addGlobalState(['inventory', item_name], quantity, 0);
}

export function purchaseInventoryItem(item, price) {
  const coins = globalState.get(['coins']) || 0;
  if (coins >= price) {
    addGlobalState(['coins'], -price);
    addToInventory(item);
    return true;
  }

  return false;
}

export function getSmallestBattery() {
  if (getCurrentItemQuantity('small_battery') > 0) return 'small_battery';
  if (getCurrentItemQuantity('medium_battery') > 0) return 'medium_battery';
  if (getCurrentItemQuantity('large_battery') > 0) return 'large_battery';
  if (getCurrentItemQuantity('extra_large_battery') > 0) return 'extra_large_battery';

  return null;
}

export function getLargestBattery() {
  if (getCurrentItemQuantity('extra_large_battery') > 0) return 'extra_large_battery';
  if (getCurrentItemQuantity('large_battery') > 0) return 'large_battery';
  if (getCurrentItemQuantity('medium_battery') > 0) return 'medium_battery';
  if (getCurrentItemQuantity('small_battery') > 0) return 'small_battery';

  return null;
}

export function replaceBattery() {
  const smallest = getLargestBattery();
  if (consumeItem(smallest)) {
    const batteryAmounts = {
      small_battery: 100,
      medium_battery: 300,
      large_battery: 600,
      extra_large_battery: 1000,
    };
    const currentState = globalState.get(['danbiao']);
    const updatedState = currentState; // still references the same object
    updatedState.battery = batteryAmounts[smallest] || 10;
    updatedState.max_battery = batteryAmounts[smallest] || 10;
    updatedState.battery_swaps = (updatedState.battery_swaps || 0) + 1;
    updatedState.battery_percent = parseInt((updatedState.battery / updatedState.max_battery) * 100);
    globalState.set(['danbiao'], updatedState);
  }
}
