import { Howl } from 'howler';
import globalState from './globalState';

export default class Sound {
  constructor() {
    const trill1 = 'https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/trill+1.mp3';
    const trill2 = 'https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/trill+2.mp3';
    const trill3 = 'https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/trill+3.mp3';
    const trill4 = 'https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/trill+4.mp3';
    const trill5 = 'https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/trill+5.mp3';
    const trill6 = 'https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/trill+6.mp3';
    const trill7 = 'https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/trill+7.mp3';
    const trill8 = 'https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/trill+8.mp3';
    const trill9 = 'https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/trill+9.mp3';
    const trill10 = 'https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/trill+10.mp3';

    this.trills = [
      trill1,
      trill2,
      trill3,
      trill4,
      trill5,
      trill6,
      trill7,
      trill8,
      trill9,
      trill10
    ];
  }
  playTrill(trillNumber) {
    return new Promise((resolve, reject) => {
      const sound = new Howl({
        src: [this.trills[trillNumber]],
        loop: false,
        volume: .25,
        mute: globalState.get('muteSound'),
        stereo: .9,
        onloaderror: (error) => {
          console.log('error', error);
          reject(error);
        },
        onend: () => {
          resolve();
        }
      });
      sound.play();
    })
  }
  mute = () => {
    globalState.set('muteSound', true);
  }
  unmute = () => {
    globalState.set('muteSound', false);
  }
  toggleMute = () => {
    if (globalState.get('muteSound')) this.unmute();
    else this.mute();
  }
}

const globalSound = new Sound();

export { globalSound };
