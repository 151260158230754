import React from 'react';
import { globalSound } from '../../glue/Sound';
import withMutastate from '../../glue/withMutastate';
import globalState from '../../glue/globalState';
import SoundOn from '@material-ui/icons/VolumeUp';
import SoundOff from '@material-ui/icons/VolumeOff';

class DanbiaoRightSpeakerButton extends React.Component {
  constructor(props) {
    super(props);
    props.agent.listen('muteSound');
  }

  audio = (state) => {
    let soundState = "https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/danbiao "
    if(state === "on") {
      soundState = soundState + "right right.png"
    } else {
      soundState = soundState + "right left.png"
    }
    return soundState
  }

  render () {
    const { style } = this.props;
    const switchSource = globalState.get('muteSound') ? this.audio('off') : this.audio('on');
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', backgroundImage: `url('${switchSource}')`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', ...style }} onClick={globalSound.toggleMute}>
        <SoundOff style={{ position: 'relative', height: '150%', width: '120%', left: '-25%' }} />
        <SoundOn style={{ position: 'relative', height: '150%', width: '120%', right: '-29%' }} />
      </div>
    );
  }
}

export default withMutastate(DanbiaoRightSpeakerButton);
