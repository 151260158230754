export default function getColorForTemperature(temperature, maxTemperature = 10) {
  const scale = maxTemperature / 2;

  if (temperature < scale) {
    const subClickNormal = parseInt((temperature / scale) * 255);
    return `rgba(${subClickNormal},${subClickNormal},${255 - subClickNormal})`;
  }

  const subClickNormal = parseInt(((temperature - scale) / scale) * 255);
  return `rgba(${255},${255 - subClickNormal},${0})`;
}
