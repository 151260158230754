import { singleton } from 'mutastate';

const globalState = singleton();

export function addGlobalState(key, value, minimum, maximum) {
  const current = globalState.get(key);
  let updated = (current || 0) + value;
  if (minimum !== undefined) {
    updated = Math.max(updated, minimum);
  }
  if (maximum !== undefined) {
    updated = Math.min(updated, maximum);
  }
  globalState.set(key, updated);
}

export default globalState;
