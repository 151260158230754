import globalState from './globalState';
import { throttle } from 'lodash';

export function getDefaultState() {
  return {
    current_phase: 'primordial',
  };
}

export function loadState() {
  const fullStateString = localStorage.getItem('danbiao_state');
  let fullState = {};
  try {
    fullState = JSON.parse(fullStateString);
  } catch (err) {
    fullState = getDefaultState();
  }
  return fullState;
}

export function save() {
  localStorage.setItem('danbiao_state', JSON.stringify(globalState.getEverything()));
}

const throttledSave = throttle(save, 1000);

export default function setupState() {
  const state = loadState();
  globalState.setEverything(state);
  globalState.addChangeHook(throttledSave);
  return state;
}
