import React from "react";

const DisciplineIcon = props => (
  <svg viewBox={'0 0 39 29'} {...props}>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group-7" transform="translate(1.000000, 0.000000)">
        <path d="M11.5,28 C15.9900953,28 19.8791661,25.426708 21.7728987,21.6744377 C22.5578655,20.1190926 11.5,18.3611793 11.5,16.5 C11.5,14.4695918 19.8544592,8.46067474 18.7013188,7.53337426 C16.7304032,5.94845794 14.2259637,5 11.5,5 C5.14872538,5 -3.73034936e-14,10.1487254 -3.73034936e-14,16.5 C-3.73034936e-14,22.8512746 5.14872538,28 11.5,28 Z" id="Oval" stroke="currentColor" strokeWidth="2"></path>
        <rect id="Rectangle" fill="currentColor" transform="translate(29.000000, 5.500000) rotate(-315.000000) translate(-29.000000, -5.500000) " x="28" y="0" width="2" height="11"></rect>
        <rect id="Rectangle-Copy-7" fill="currentColor" transform="translate(32.000000, 11.500000) rotate(-281.000000) translate(-32.000000, -11.500000) " x="31" y="6" width="2" height="11"></rect>
        <rect id="Rectangle-Copy-8" fill="currentColor" transform="translate(32.000000, 18.500000) rotate(-262.000000) translate(-32.000000, -18.500000) " x="31" y="13" width="2" height="11"></rect>
      </g>
    </g>
  </svg>
);

export default DisciplineIcon;
