import React from "react";

const HealthIcon = props => (
  <svg viewBox={'0 0 45 32'} {...props}>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group-2" transform="translate(1.555872, 1.500000)">
            <path d="M2.10438504,2.03062836 C9.01223046,0.67687612 14.7407565,0 19.2899631,0 C23.8454544,0 30.4565997,0.678747627 39.1233991,2.03624288 C40.0597551,2.18290589 41.8008326,4.59082231 41.7037817,5.5729896 C41.1976023,10.6955907 40.7777176,14.6712609 40.4441277,17.5 C40.0465778,20.8710994 38.8672893,24.8710994 36.9062621,29.5 L5.83608079,29.5 C3.40039874,24.8626499 1.93641436,20.8626499 1.44412766,17.5 C1.00934855,14.5301656 0.528798413,10.283179 0.00247724805,4.75904014 C-0.0671384713,4.02837049 1.3474911,2.17895925 2.10438504,2.03062836 Z" id="Rectangle" stroke="currentColor" strokeWidth="2"></path>
            <rect id="Rectangle" fill="currentColor" x="19.4441277" y="4.5" width="2" height="10"></rect>
            <rect id="Rectangle-Copy" fill="currentColor" transform="translate(31.444128, 11.500000) rotate(-345.000000) translate(-31.444128, -11.500000) " x="30.4441277" y="6.5" width="2" height="10"></rect>
            <rect id="Rectangle-Copy-2" fill="currentColor" transform="translate(9.444128, 11.500000) rotate(-15.000000) translate(-9.444128, -11.500000) " x="8.44412766" y="6.5" width="2" height="10"></rect>
            <polygon id="Rectangle" fill="currentColor" transform="translate(21.444128, 29.500000) rotate(-315.000000) translate(-21.444128, -29.500000) " points="14.4441277 22.5 28.4441277 22.5 14.4441277 36.5"></polygon>
        </g>
    </g>
  </svg>
);

export default HealthIcon;
