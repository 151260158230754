import React from 'react';

export default function PercentBar({ percentage, units = 5 }) {
  const currentUnits = Math.round((percentage / 100) * units);
  const blocks = [];

  for (let dex = 0; dex < units; dex += 1) {
    blocks.push(<div style={{ marginRight: dex < units - 1 ? 2 : 0, width: 5, height: 5, backgroundColor: currentUnits > dex ? '#000' : '#ccc' }}></div>)
  }
  return (
    <div style={{ height: 5, borderRadius: 2, display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {blocks}
      </div>
    </div>
  );
}
