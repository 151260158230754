import React from 'react';
import AspectBox from '../../glue/AspectBox';
import DanbiaoScreen from '../components/DanbiaoScreen';
import globalState, { addGlobalState } from '../../glue/globalState';
import withMutastate from '../../glue/withMutastate';
import DanbiaoLeftSpeaker from '../components/DanbiaoLeftSpeaker';
import DanbiaoFront from '../components/DanbiaoFront';
import DanbiaoBack from '../components/DanbiaoBack';
import Shop from '../components/Shop';
import Modal from '../components/Modal';
import { consumeItem, getSmallestBattery, getCurrentItemQuantity, getLargestBattery, replaceBattery } from '../../glue/Inventory';

function getDefaultState() {
  return {
    happiness: 50,
    health: 10,
    fullness: 50,
    sleepiness: 0,
    battery: 10,
    battery_swaps: 0,
    max_battery: 100,
    battery_percent: 100,
    discipline: 50,
  };
}

function getDefaultInventoryState() {
  return {
    small_battery: 5,
    medkit: 1,
  };
}

class Danbiao extends React.Component {
  state = { cursor: null, side: 'front' };
  constructor(props) {
    super(props);

    props.agent.listen(['danbiao']);
    globalState.set(['danbiao'], { ...getDefaultState(), ...(globalState.get(['danbiao']) || {}) });
    globalState.set(['inventory'], { ...getDefaultInventoryState(), ...(globalState.get(['inventory']) || {}) });
  }

  componentDidMount() {
    this.resetTimer();
  }

  componentWillUnmount() {
    this.stopTimer();
  }

  resetTimer = () => {
    if (!this.timer) {
      this.timer = setInterval(this.tick, 1000);
    }
  }

  stopTimer = () => {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }

  tick = () => {
    addGlobalState('currentTicks', 1);
    this.currentTicks = (this.currentTicks || 0) + 1;

    if (globalState.get('currentTicks') > 10 * 60) {
      globalState.set('current_phase', 'win');
    }
    const currentState = globalState.get(['danbiao']);
    const updatedState = currentState; // still references the same object
    updatedState.fullness = Math.max(updatedState.fullness - 1, 0);
    updatedState.battery = Math.max(updatedState.battery - 1, 0);
    updatedState.battery_percent = parseInt((updatedState.battery / (updatedState.max_battery || 100)) * 100);

    updatedState.sleepiness = Math.min(updatedState.sleepiness + 1, 100);
    // updatedState.happiness = Math.max(updatedState.happiness - 1, 0);
    // updatedState.discipline = Math.max(updatedState.discipline - 1, 0);
    if (this.currentTicks % 3 === 0) {
      addGlobalState(['coins'], 1 + (getCurrentItemQuantity('boss')));
    }
    if (updatedState.fullness <= 0) {
      updatedState.health = Math.max(updatedState.health - 1, 0);
    }
    if (updatedState.sleepiness > 90) {
      updatedState.happiness = Math.max(updatedState.happiness - 1, 0);
    }
    if (updatedState.health <= 0) {
      // you died bruh
      this.stopTimer();
      this.setState({ dying: true, framesRemaining: 300 });
      let deathAnimation;
      deathAnimation = setInterval(() => {
        this.setState({ framesRemaining: this.state.framesRemaining - 1 });
        if (this.state.framesRemaining <= 0) {
          clearInterval(deathAnimation);
          globalState.setEverything({ current_phase: 'game_over' });
        }
      }, 10);
    } else if (updatedState.battery <= 0) {
      globalState.setEverything({ current_phase: 'game_over' });
    } else {
      globalState.set(['danbiao'], updatedState);
    }
  }

  getActive() {
    const cursor = this.getCursor();
    if (cursor === null) return null;

    const options = [
      'food',
      'lights',
      'play',
      'medicine',

      'wash',
      'health',
      'discipline',
      'attention',
    ];
    return options[cursor % options.length];
  }

  getCursor = () => {
    // return globalState.get(['danbiao', 'cursor']) || 0;
    return this.state.cursor;
  }
  setCursor = (position) => {
    this.setState({ cursor: position });
  }

  clickLeft = () => {
    this.setCursor((this.getCursor() === null) ? 0 : this.getCursor() + 1);
  }
  clickMiddle = () => {
    globalState.set(['danbiao', 'chosen'], this.getActive());

    this.clickIcon(this.getActive());
    if (getCurrentItemQuantity('cursor_stabilizer') < 1) {
      this.setCursor(null);
    }
  }
  clickRight = () => {
    this.setCursor(null);
    globalState.set(['danbiao', 'chosen'], null);
  }

  clickIcon = (icon) => {
    if (icon === 'food') {
      if (globalState.get(['danbiao', 'happiness']) > 10) {
        if (globalState.get(['danbiao', 'fullness']) < 80) {
          addGlobalState(['danbiao', 'fullness'], 10, 0, 100);
          addGlobalState(['danbiao', 'health'], 1, 0, 100);
        } else {
          addGlobalState(['danbiao', 'fullness'], 5, 0, 100);
          addGlobalState(['danbiao', 'health'], -1, 0, 100);
          addGlobalState(['danbiao', 'discipline'], -1, 0, 100);
        }
      } else {
        // you're too unhappy to eat
      }
    }
    if (icon === 'lights') {
      if (globalState.get(['danbiao', 'discipline']) > 10) {
        addGlobalState(['danbiao', 'sleepiness'], -10, 0, 100);
      } else {
        // too undisciplined to sleep
      }
    }
    if (icon === 'play') {
      addGlobalState(['danbiao', 'happiness'], 10, 0, 100);
      addGlobalState(['danbiao', 'discipline'], -1, 0, 100);
      addGlobalState(['danbiao', 'fullness'], -1, 0, 100);
      if (globalState.get(['danbiao', 'fullness']) || 0 > 70) {
        addGlobalState(['danbiao', 'health'], +1, 0, 100);
      }
    }
    if (icon === 'discipline') {
      addGlobalState(['danbiao', 'discipline'], 10, 0, 100);
      addGlobalState(['danbiao', 'happiness'], -1, 0, 100);
    }
    if (icon === 'medicine') {
      if (consumeItem('medkit')) {
        addGlobalState(['danbiao', 'health'], 10, 0, 100);
      }
    }
    if (icon === 'attention') {
      if (globalState.get(['danbiao', 'happiness']) > 10) {
        addGlobalState(['danbiao', 'happiness'], 2, 0, 100);
      } else {
        // too unhappy for attention to affect happiness
      }
    }
  }

  renderLowBattery() {
    return (
      <div style={{ zIndex: 40, position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, backgroundColor: 'rgba(55,55,55,.8)', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: 5, fontFamily: 'monospace', }}>
        <h3 style={{ textAlign: 'center' }}>Replace The Battery Quick!</h3>

        {getLargestBattery() ? <button onClick={replaceBattery}>Replace</button> : <div>YOU HAVE NO BATTERIES LEFT, BUY ONE!!!</div>}
      </div>
    );
  }

  clickScreenIcon = (...props) => {
    if (getCurrentItemQuantity('touch_screen') > 0) {
      this.clickIcon(...props);
    }
  }

  renderFront() {
    const battery = globalState.get(['danbiao', 'battery']) || 0;

    return (
      <DanbiaoFront clickLeft={this.clickLeft} clickRight={this.clickRight} clickMiddle={this.clickMiddle} style={{ width: '100%', height: '100%' }}>
        {battery < 10 && this.renderLowBattery()}
        <DanbiaoScreen dying={this.state.dying} clickIcon={this.clickScreenIcon} battery={battery} active={this.getActive()} ></DanbiaoScreen>
      </DanbiaoFront>
    );
  }

  renderShopModal() {
    return (
      <Modal onExit={() => this.setState({ showShop: !this.state.showShop })}><Shop /></Modal>
    );
  }

  render() {
    const { style } = this.props;
    const { side, showShop } = this.state;

    return (
      <div style={style ? Object.assign({}, styles.container, style) : styles.container}>
        {showShop && this.renderShopModal()}
        <div style={{ flex: 1, alignSelf: 'stretch', display: 'flex' }}>
          <AspectBox>
            {side === 'front' ? this.renderFront() : <DanbiaoBack style={{ width: '100%', height: '100%' }} />}
          </AspectBox>
        </div>
        {/* <textarea value={JSON.stringify(globalState.get(['danbiao']), null, 2)} style={{ position: 'absolute', opacity: .5, right: 0, top: 0, width: 300, height: 300, backgroundColor: 'blue', color: 'white' }}>
        </textarea> */}
        <div>
          <button onClick={() => this.setState({ side: this.state.side === 'front' ? 'back' : 'front' })}>swap</button>
          <button onClick={() => this.setState({ showShop: !this.state.showShop })}>shop</button>
        </div>
      </div>
    );
  }
}

export default withMutastate(Danbiao);

const styles = {
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    overflow: 'hidden',
  }
};
