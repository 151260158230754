import React from 'react';
import Spritesheet from 'react-responsive-spritesheet';

export default function PixelCreature() {
  return (
    <Spritesheet
      image={`https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/bw%20monster%20sheet.png`}
      style={{ width: '50%' }}
      widthFrame={64}
      heightFrame={64}
      steps={24}
      fps={8}
      autoplay={true}
      loop={true}
    />
  );
}
