import React from 'react';
import FoodIcon from '../icons/FoodIcon';
import PixelCreature from './PixelCreature';
import DyingCreature from './DyingCreature';
import PlayIcon from '../icons/PlayIcon';
import HealthIcon from '../icons/HealthIcon';
import MedicineIcon from '../icons/MedicineIcon';
import LightIcon from '../icons/LightIcon';
import AttentionIcon from '../icons/AttentionIcon';
import WashIcon from '../icons/WashIcon';
import DisciplineIcon from '../icons/DisciplineIcon';
import DanbiaoIcon from './DanbiaoIcon';
import { globalSound } from '../../glue/Sound';

export default class DanbiaoScreen extends React.Component {
  renderMiddleScreen() {
    const { dying } = this.props;

    return (
      <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {dying ? <DyingCreature /> : <PixelCreature />}
      </div>
    );
  }
  clickIcon = (name, dex) => {
    globalSound.playTrill(dex + 1);
    this.props.clickIcon(name, dex);
  }
  renderMenuIcon({ name, Icon, stateKey, dex }) {
    return (
      <DanbiaoIcon dex={dex} onClick={this.clickIcon} stateKey={stateKey} active={name === this.props.active} name={name} Icon={Icon} />
    );
  }
  render() {
    const { style, battery } = this.props;

    return (
      <div style={Object.assign({ opacity: (battery / 100).toFixed(2) }, styles.container, style || {})}>
        <div style={{ height: '12%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingLeft: '4%', paddingRight: '4%', paddingTop: '4%' }}>
          {this.renderMenuIcon({ dex: 0, name: 'food', Icon: FoodIcon, stateKey: 'fullness' })}
          {this.renderMenuIcon({ dex: 1, name: 'lights', Icon: LightIcon, stateKey: 'sleepiness' })}
          {this.renderMenuIcon({ dex: 2, name: 'play', Icon: PlayIcon, stateKey: 'happiness' })}
          {this.renderMenuIcon({ dex: 3, name: 'medicine', Icon: MedicineIcon, stateKey: 'health' })}
        </div>
        <div style={{ flex: 1, overflow: 'auto', display: 'flex' }}>
          {this.renderMiddleScreen()}
        </div>
        <div style={{ height: '12%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingLeft: '4%', paddingRight: '4%', paddingBottom: '4%' }}>
          {this.renderMenuIcon({ dex: 4, name: 'wash', Icon: WashIcon, stateKey: 'cleanliness' })}
          {this.renderMenuIcon({ dex: 5, name: 'health', Icon: HealthIcon })}
          {this.renderMenuIcon({ dex: 6, name: 'discipline', Icon: DisciplineIcon, stateKey: 'discipline' })}
          {this.renderMenuIcon({ dex: 7, name: 'attention', Icon: AttentionIcon })}
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    padding: 5,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    fontFamily: 'monospace',
  }
};
