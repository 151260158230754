import React from 'react';
import Primordial from './phases/Primordial';
import Danbiao from './phases/Danbiao';
import Human from './phases/Human';
import withMutastate from '../glue/withMutastate';
import globalState from '../glue/globalState';
import WinScreen from './components/WinScreen';

export class Game extends React.Component {
  constructor(props) {
    super(props);

    props.agent.listen(['current_phase']);
  }
  renderCurrentPhase() {
    const { current_phase = 'primordial' } = (this.props.data || {});

    if (current_phase === 'primordial') {
      return <Primordial />;
    }
    if (current_phase === 'danbiao') {
      return <Danbiao />;
    }
    if (current_phase === 'human') {
      return <Human />;
    }
    if (current_phase === 'game_over') {
      return <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>Game Over <button onClick={() => globalState.setEverything({ current_phase: 'primordial' })}>restart</button></div>;
    }
    if (current_phase === 'win') {
      return <WinScreen />
    }
  }
  renderDebug() {
    return (
      <div style={{ position: 'absolute', left: 0, top: 0, zIndex: 100 }}>
        <button onClick={() => globalState.set('current_phase', 'primordial')}>primordial</button>
        <button onClick={() => globalState.set('current_phase', 'danbiao')}>danbiao</button>
        <button onClick={() => globalState.set('current_phase', 'human')}>human</button>
        <button onClick={() => globalState.set('current_phase', 'game_over')}>game_over</button>
        <button onClick={() => globalState.set('current_phase', 'win')}>win</button>
        <button onClick={() => globalState.setEverything({ current_phase: 'primordial' })}>reset state</button>
      </div>
    );
  }
  render() {
    const { style } = this.props;

    return (
      <div style={style ? Object.assign({}, styles.container, style) : styles.container}>
        {/* {this.renderDebug()} */}
        {this.renderCurrentPhase()}
      </div>
    );
  }
}

export default withMutastate(Game);

const styles = {
  container: {
    height: '100%',
  }
};
