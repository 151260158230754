import React from 'react';
import { globalMusic } from '../../glue/Music';
import withMutastate from '../../glue/withMutastate';
import globalState from '../../glue/globalState';
import MusicOn from '@material-ui/icons/MusicNote';
import MusicOff from '@material-ui/icons/MusicOff';

class DanbiaoLeftSpeakerButton extends React.Component {
  constructor(props) {
    super(props);
    props.agent.listen('muteMusic');
  }

  componentDidMount() {
    globalMusic.startMusic();
  }

  audio = (state) => {
    let musicState = "https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/danbiao "
    if(state === "on") {
      musicState = musicState + "left right.png"
    } else {
      musicState = musicState + "left left.png"
    }
    return musicState
  }

  render () {
    const { style } = this.props;
    const switchSource = globalState.get('muteMusic') ? this.audio('off') : this.audio('on');
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', backgroundImage: `url('${switchSource}')`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', ...style }} onClick={globalMusic.toggleMute}>
        <MusicOff style={{ position: 'relative', height: '150%', width: '120%', left: '-22%' }} />
        <MusicOn style={{ position: 'relative', height: '150%', width: '120%', right: '-22%' }} />
      </div>
    );
  }
}

export default withMutastate(DanbiaoLeftSpeakerButton);
