import React from 'react';
import DanbiaoLeftSpeakerButton from './DanbiaoLeftSpeaker';
import DanbiaoRightSpeakerButton from './DanbiaoRightSpeaker';
import withMutastate from '../../glue/withMutastate';
import globalState from '../../glue/globalState';

class DanbiaoFront extends React.Component {
  constructor(props) {
    super(props);

    props.agent.listen(['danbiao', 'battery']);
  }
  render() {
    const { style, children, clickLeft, clickMiddle, clickRight } = this.props;

    return (
      <div style={{ background: `url(https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/danbiao%20front.png)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', ...(style || {}) }}>
        <div style={{ position: 'relative', top: "8%", height: "8%", background: `url(https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/title.png)`, backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
        </div>
        <div style={{ position: 'absolute', top: '19.5%', left: '25%', right: '25%', height: '50%' }} >
          {children}
        </div>
        <DanbiaoLeftSpeakerButton style={{ position: 'absolute', bottom: '30.5%', left: '9.6%', width: '6.1%', height: '3.5%' }} />
        <div onClick={clickLeft} style={{ position: 'absolute', bottom: '13%', left: '25%', width: '13%', height: '13%' }}></div>
        <div onClick={clickRight} style={{ position: 'absolute', bottom: '13%', right: '25%', width: '13%', height: '13%' }}></div>
        <div onClick={clickMiddle} style={{ position: 'absolute', bottom: '13%', left: '43%', width: '13%', height: '13%' }}></div>
        <div style={{ backgroundColor: globalState.get(['danbiao', 'battery_percent']) > 30 ? 'green' : 'red', borderRadius: 4, width: 8, height: 8, position: 'absolute', right: '12%', bottom: '50%' }}></div>
        <DanbiaoRightSpeakerButton style={{ position: 'absolute', bottom: '30.5%', right: '9.6%', width: '6.1%', height: '3.5%', backgroundColor: 'white' }} />
      </div>
    );
  }
}

export default withMutastate(DanbiaoFront);

const styles = {
  container: {
    padding: 5,
  }
};
