import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Game from './game/Game';
import * as serviceWorker from './serviceWorker';
import setupState from './glue/setupState';

setupState();

ReactDOM.render(<React.StrictMode><Game /></React.StrictMode>, document.getElementById('root'));

serviceWorker.unregister();
