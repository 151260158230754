import React from "react";

const LightIcon = props => (
  <svg viewBox={'0 0 24 32'} {...props}>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group-4" transform="translate(1.000000, 1.000000)">
            <path d="M11,0 C17.0751322,0 22,4.70101013 22,10.5 C22,14.9560582 19.0920188,18.7637936 14.9886995,20.2884105 C14.9963526,20.5225749 15,20.7601921 15,21 C15,25.9705627 14.4797919,30 11.5,30 C8.5202081,30 8,25.9705627 8,21 C8,20.8677755 8.00110888,20.7362169 8.00330402,20.6053825 C3.38483445,19.3604639 0,15.3075497 0,10.5 C0,4.70101013 4.92486775,0 11,0 Z" id="Oval-3" stroke="currentColor" stroke-width="2"></path>
            <polygon id="Rectangle" fill="currentColor" transform="translate(11.632795, 17.252419) rotate(-360.000000) translate(-11.632795, -17.252419) " points="11.2655901 11 13.2655901 11 12.3833719 14.4703293 13.2655901 17 11.8984301 20.920192 13.2655901 23.5048372 11.2655901 23.5048372 10 20.6472105 11.2655901 17.0665905 10.3525123 14.413564"></polygon>
        </g>
    </g>
  </svg>
);

export default LightIcon;
