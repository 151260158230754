import React from "react";

const MedicineIcon = props => (
  <svg viewBox={'0 0 31 38'} {...props}>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group-3" transform="translate(1.000000, 0.000000)">
            <polygon id="Rectangle" stroke="currentColor" strokeWidth="2" points="0.41866523 7 22.9243546 7 27.3796855 8 28.3796855 11 24.2961717 27 2.66955888 27 -4.79616347e-14 9.08791382"></polygon>
            <rect id="Rectangle" fill="currentColor" transform="translate(22.233465, 16.500000) rotate(6.000000) translate(-22.233465, -16.500000) " x="21.7334647" y="10" width="1" height="13"></rect>
            <rect id="Rectangle" fill="currentColor" x="9.73346471" y="11" width="3" height="11"></rect>
            <rect id="Rectangle-Copy-3" fill="currentColor" transform="translate(11.233465, 16.500000) rotate(-270.000000) translate(-11.233465, -16.500000) " x="9.73346471" y="10.5" width="3" height="12"></rect>
            <path d="M13.2334647,-4.88498131e-15 C17.9278851,-4.88498131e-15 21.7334647,2.46243388 21.7334647,5.5 C21.7334647,5.66869722 21.721727,5.83562052 21.6987511,6.00044662 L20.6438916,6.00033654 C20.0851786,3.36765012 16.9801584,1.35294118 13.2334647,1.35294118 C9.48677107,1.35294118 6.38175082,3.36765012 5.82303779,6.00033654 L4.76817836,6.00044662 C4.74520247,5.83562052 4.73346471,5.66869722 4.73346471,5.5 C4.73346471,2.46243388 8.53904434,-4.88498131e-15 13.2334647,-4.88498131e-15 Z" id="Oval-2" fill="currentColor"></path>
        </g>
      </g>
  </svg>
);

export default MedicineIcon;
