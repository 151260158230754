import React from 'react';
import AutoSizer from './AutoSizer';

export default function AspectBox({ children, style = {} }) {
  return (
    <AutoSizer style={{ flex: 1, width: 'auto', height: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {({ width, height }) => {
        const min = Math.min(width, height);

        return <div style={Object.assign({ width: min, height: min }, style)}>{children}</div>;
      }}
    </AutoSizer>
  );
}
