import React from 'react';
import { replaceBattery } from '../../glue/Inventory';

export default class DanbiaoBack extends React.Component {
  render() {
    const { style, children, clickPanel, clickScrew } = this.props;

    return (
      <div style={{ background: `url(https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/danbiao%20back.png)`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', ...(style || {}) }}>
        <div style={{ position: 'absolute', top: '19%', left: '25%', right: '25%', height: '50%' }} >
          {children}
        </div>
        <div onClick={clickPanel} style={{ position: 'absolute', bottom: '22%', left: '25%', width: '50%', height: '28%' }}></div>
        <div onClick={replaceBattery} style={{ position: 'absolute', bottom: '12.5%', left: '44%', width: '12%', height: '9%' }}></div>
      </div>
    );
  }
}

const styles = {
  container: {
    padding: 5,
  }
};
