import React from "react";

const WashIcon = props => (
  <svg viewBox={'0 0 22 37'} {...props}>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group-6" transform="translate(1.847607, 1.964743)" stroke="currentColor" strokeWidth="2">
            <polygon id="Rectangle" points="4 25.3884952 16 21.0352574 17.5617586 30.0352574 9.67795374 32.904797"></polygon>
            <path d="M11.0023387,8.07193306 C11.0771479,12.4900287 12.9276068,16.0393973 15.1354542,15.9996697 C17.3433016,15.9599422 19.0724706,12.3461625 18.9976613,7.92806694 C18.9228521,3.50997135 17.0723932,-0.0393972603 14.8645458,0.000330269183 C12.6566984,0.0400577987 10.9275294,3.65383746 11.0023387,8.07193306 Z" id="Oval-Copy-5"></path>
            <path d="M9.32048273,24.2285219 C13.6774546,22.755556 16.6182779,19.6683075 15.8889954,17.3329593 C15.159713,14.9976112 11.0364891,14.2985122 6.67951727,15.7714781 C2.3225454,17.244444 -0.618277918,20.3316925 0.111004556,22.6670407 C0.840287029,25.0023888 4.96351085,25.7014878 9.32048273,24.2285219 Z" id="Oval"></path>
        </g>
    </g>
  </svg>
);

export default WashIcon;
