import React from 'react';
import globalState from '../../glue/globalState';
import BatteryIcon from '@material-ui/icons/BatteryCharging20';
import LargeBatteryIcon from '@material-ui/icons/BatteryCharging50';
import ExtraLargeBatteryIcon from '@material-ui/icons/BatteryCharging80';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import PlantIcon from '@material-ui/icons/LocalFlorist';
import PlayIcon from '@material-ui/icons/SportsFootball';
import SleepIcon from '@material-ui/icons/SingleBed';
import DisciplineIcon from '@material-ui/icons/SportsCricket';
import MedicineIcon from '@material-ui/icons/LocalHospital';
import TouchScreenIcon from '@material-ui/icons/TouchApp';
import CursorIcon from '@material-ui/icons/Mouse';
import SpatulaIcon from '@material-ui/icons/Restaurant';
import WalletIcon from '@material-ui/icons/AccountBalanceWallet';
import BossIcon from '@material-ui/icons/Business';
import TranslatorIcon from '@material-ui/icons/Translate';
import { purchaseInventoryItem, getCurrentItemQuantity } from '../../glue/Inventory';

function ShopItem({ name, icon, price, description, inventory }) {
  const Icon = icon || BatteryIcon;
  return (
    <div onClick={() => purchaseInventoryItem(inventory, price)} style={{ cursor: 'pointer', padding: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', fontWeight: 200, borderRadius: 10, border: '1px solid #eee', marginTop: 10 }}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <div style={{ marginRight: 10, fontSize: 24 }}>{getCurrentItemQuantity(inventory) || 0}</div>
        <Icon style={{ width: 45, height: 45 }} />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ marginLeft: 10, fontSize: 18, fontWeight: 100 }}>{name}</div>
          {description && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: 12, fontWeight: 400 }}>
            <div style={{ marginLeft: 10 }}>{description}</div>
          </div>}
        </div>
      </div>
      <div style={{ flex: 1 }}></div>
      <div style={{ marginLeft: 10, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <div style={{ marginLeft: 10 }}>{price}</div>
        <MonetizationOnIcon style={{ color: '#555500', marginLeft: 5, width: 15, height: 15 }} />
      </div>
    </div>
  );
}

export default class Shop extends React.Component {
  renderCurrentCoins() {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', fontSize: 24, fontWeight: 100 }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>You Currently Have</div>
        <div style={{ marginLeft: 10, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>{`[${globalState.get('coins') || 0}]`}</div>
        <div style={{ marginLeft: 10, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>Coins</div>
      </div>
    );
    return;
  }

  renderCurrentShopItems() {
    const result = [
      <ShopItem icon={BatteryIcon} name="Small Battery" description="1 minute of play time" price={5} inventory="small_battery" />,
      <ShopItem icon={LargeBatteryIcon} name="Large Battery" description="2 minute of play time" price={20} inventory="large_battery" />,
      <ShopItem icon={ExtraLargeBatteryIcon} name="Extra Large Battery" description="4 minute of play time" price={50} inventory="extra_large_battery" />,
      <ShopItem icon={BossIcon} name="Boss" description="Make twice as much money" price={100} inventory="boss" />,
    ];
    if (getCurrentItemQuantity('translator') < 1) {
      result.push(<ShopItem icon={TranslatorIcon} name="Universal Translator" description="What is russian" price={20} inventory="translator" />);
    }
    if (getCurrentItemQuantity('cursor_stabilizer') < 1) {
      result.push(<ShopItem icon={CursorIcon} name="Cursor Stabilizer" description="Keep the cursor from resetting when you push the middle button" price={100} inventory="cursor_stabilizer" />);
    }
    if (getCurrentItemQuantity('touch_screen') < 1) {
      result.push(<ShopItem icon={TouchScreenIcon} name="Touchscreen" description="Click on icons instead of using the three buttons" price={100} inventory="touch_screen" />);
    }
    return result;
  }
  render() {
    const { style } = this.props;

    return (
      <div style={style ? Object.assign({}, styles.container, style) : styles.container}>
        {this.renderCurrentCoins()}
        {this.renderCurrentShopItems()}
      </div>
    );
  }
}

const styles = {
  container: {
    padding: 5,
    overflow: 'auto',
  }
};
