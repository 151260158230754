import React from 'react';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import SoundIcon from '@material-ui/icons/MusicNote';

export default class Human extends React.Component {
  render() {
    const { style } = this.props;

    return (
      <div style={style ? Object.assign({}, styles.container, style) : styles.container}>
        <div>
          Human
          <AccessAlarmIcon />
          <SoundIcon />
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    padding: 5,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }
};
