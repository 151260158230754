import React from "react";

const AttentionIcon = props => (
  <svg viewBox={'0 0 35 32'} {...props}>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group-5" transform="translate(1.000000, 1.000000)">
        <path d="M10,0 C15.5228475,0 20,4.4771525 20,10 C20,10.1548902 19.9964785,10.308958 19.9895113,10.4621276 C20.9385986,10.1617001 21.9504157,10 23,10 C28.5228475,10 33,14.4771525 33,20 C33,25.5228475 28.5228475,30 23,30 C17.4771525,30 13,25.5228475 13,20 C13,19.8451098 13.0035215,19.691042 13.0104887,19.5378724 C12.0614014,19.8382999 11.0495843,20 10,20 C4.4771525,20 0,15.5228475 0,10 C0,4.4771525 4.4771525,0 10,0 Z" id="Oval-4" stroke="currentColor" strokeWidth="2"></path>
        <polygon id="Rectangle" fill="currentColor" points="6 12 13 12 9.5 15"></polygon>
        <polygon id="Rectangle-Copy-6" fill="currentColor" points="20 26 27 26 23.5 23"></polygon>
        <rect id="Rectangle" fill="currentColor" x="5" y="6" width="2" height="4"></rect>
        <rect id="Rectangle-Copy-4" fill="currentColor" x="20" y="18" width="2" height="4"></rect>
        <rect id="Rectangle" fill="currentColor" x="10" y="6" width="3" height="4"></rect>
        <rect id="Rectangle-Copy-5" fill="currentColor" x="25" y="18" width="3" height="4"></rect>
      </g>
    </g>
  </svg>
);

export default AttentionIcon;
