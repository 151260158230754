import React from 'react';
import withMutastate from '../../glue/withMutastate';
import PercentBar from './PercentBar';

class DanbiaoIcon extends React.Component {
  constructor(props) {
    super(props);

    props.agent.listen(['danbiao', props.stateKey], { alias: 'value' });
  }
  onClick = () => {
    this.props.onClick(this.props.name, this.props.dex);
  }
  render() {
    const { name, Icon, active, stateKey, data } = this.props;
    const { value } = data;

    return (
      <div onClick={this.onClick} style={{ flex: 1, height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Icon style={{ minHeight: 10, flex: 1, height: '100%', color: active ? 'red' : 'black' }} />
        <div style={{ paddingLeft: '10%', paddingRight: '10%', paddingTop: 9, opacity: .4 }}>
          {stateKey && <PercentBar percentage={value || 0}/>}
        </div>
      </div>
    );
  }
}

export default withMutastate(DanbiaoIcon);
