import React from 'react';
import Spritesheet from 'react-responsive-spritesheet';
import withMutastate from '../../glue/withMutastate';
import globalState from '../../glue/globalState';
import getColorForTemperature from '../../glue/getColorForTemperature';
import PercentBar from '../components/PercentBar';
import AutoSizer from '../../glue/AutoSizer';

const MAX_CLICKS = 20;
const STARTING_THRESHOLD = 10;
const DECAY_THRESHOLD = 2000;
const INCREMENT_THRESHOLD = 1000;
const IDEAL_TEMP_TICK_REQUIRE_COUNT = 30;

class Primordial extends React.Component {
  constructor(props) {
    super(props);

    props.agent.listen('primordial');
  }
  state = { startingClicks: 0 };
  componentDidMount() {
    // this.restartTimer();
  }
  componentWillUnmount() {
    this.stopTimer();
  }
  restartTimer = () => {
    if (!this.timer) {
      this.timer = setInterval(this.tick, 500);
    }
  }
  stopTimer = () => {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }
  getCurrentPhase() {
    return globalState.get(['primordial', 'phase']) || 'egg_1';
  }
  setPhase(name) {
    globalState.set(['primordial', 'phase'], name);
  }
  setCurrentClicks(count) {
    globalState.set(['primordial', 'egg', 'clicks'], count);
  }
  getCurrentClicks() {
    const { startingClicks } = this.state;
    if (startingClicks < STARTING_THRESHOLD) return startingClicks;
    return (globalState.get(['primordial', 'egg', 'clicks']) || 0)
  }
  isClickCountIdeal = () => {
    return (this.getCurrentClicks() > 5 && this.getCurrentClicks() < MAX_CLICKS - 5)
  }
  isClickCountDeadly = () => {
    return !(this.getCurrentClicks() > 2 && this.getCurrentClicks() < MAX_CLICKS - 2);
  }
  incrementClicks = () => {
    this.setCurrentClicks(Math.min(this.getCurrentClicks() + 1, MAX_CLICKS));
    this.setState({ lastClick: new Date().getTime() });
  }
  decayClicks = () => {
    this.setCurrentClicks(Math.max(this.getCurrentClicks() - 1, 0));
  }
  tick = () => {
    if (this.getCurrentPhase() === 'egg_1') {
      if (this.isClickCountIdeal()) {
        this.setState({ idealQuantity: (this.state.idealQuantity || 0) + 1 }, () => {
          if (this.state.idealQuantity >= IDEAL_TEMP_TICK_REQUIRE_COUNT) {
            this.setPhase('creature_1');
            this.setState({ idealQuantity: 0 });
            setTimeout(() => {
              globalState.set(['current_phase'], 'danbiao');
            }, 2000);
          }
        });
      } else {
        this.setState({ idealQuantity: Math.max((this.state.idealQuantity || 0) - 1, 0) });
      }
      // do your stuff here, use setstate
      this.setState({ time: (this.state.time || 0) + 1  })
      if (this.getTimeSinceLastClick() > DECAY_THRESHOLD) {
        this.decayClicks();
      }
    } else if (this.getCurrentPhase() === 'creature_2') {
      if (this.isClickCountIdeal()) {
        this.setState({ idealQuantity: (this.state.idealQuantity || 0) + 1 }, () => {
          if (this.state.idealQuantity >= IDEAL_TEMP_TICK_REQUIRE_COUNT) {
            // this.setPhase('');
            globalState.set({ current_phase: 'danbiao' });
            this.setState({ idealQuantity: 0 });
          }
        });
      } else {
        this.setState({ idealQuantity: Math.max((this.state.idealQuantity || 0) - 1, 0) });
      }
      // do your stuff here, use setstate
      this.setState({ time: (this.state.time || 0) + 1  })
      if (this.getTimeSinceLastClick() > DECAY_THRESHOLD) {
        this.decayClicks();
      }
    }
  }
  getTimeSinceLastClick = () => {
    return (new Date().getTime() - (this.state.lastTemperatureChange || 0));
  }
  resetTimeSinceLastClick = () => {
    this.setState({ lastTemperatureChange: new Date().getTime() });
  }
  onClickEgg = () => {
    const { startingClicks } = this.state;
    if (!this.hasTimerStarted()) {
      this.incrementClicks();
      this.setState({ startingClicks: startingClicks + 1 });
      if (startingClicks === STARTING_THRESHOLD - 1) {
        this.restartTimer();
      }
    } else {
      if (this.getTimeSinceLastClick() < INCREMENT_THRESHOLD) {
        this.incrementClicks();
      }
    }
    this.resetTimeSinceLastClick();
  }
  hasTimerStarted = () => {
    return !!this.timer;
  }
  getTemperatureColor() {
    const backgroundTemperatureWhiteScale = [
      'rgba(255,255,255)',
      'rgba(255,248,230)',
      'rgba(255,241,204)',
      'rgba(255,234,179)',
      'rgba(255,227,153)',
      'rgba(255,220,128)',
      'rgba(255,213,102)',
      'rgba(255,206,77)',
      'rgba(255,199,51)',
      'rgba(255,192,26)',
      'rgba(255,185,0)'
    ];
    const backgroundTemperatureFullScale = [
      'rgba(0,0,190)',
      'rgba(26,19,171)',
      'rgba(51,39,152)',
      'rgba(77,58,133)',
      'rgba(102,78,114)',
      'rgba(128,97,95)',
      'rgba(153,117,76)',
      'rgba(179,136,55)',
      'rgba(204,156,36)',
      'rgba(230,165,17)',
      'rgba(255,185,0)',
      'rgba(248,165,0)',
      'rgba(241,156,0)',
      'rgba(234,136,0)',
      'rgba(227,117,0)',
      'rgba(220,97,0)',
      'rgba(213,78,0)',
      'rgba(206,58,0)',
      'rgba(199,39,0)',
      'rgba(192,19,0)',
      'rgba(185,0,0)',
    ]

    if (!this.hasTimerStarted()) {
      return backgroundTemperatureWhiteScale[this.getCurrentClicks()];
    } else {
      return backgroundTemperatureFullScale[this.getCurrentClicks()];
    }
  }
  getSecondaryColor() {
    if (!this.hasTimerStarted()) {
      return `rgba(${255},${255},${255 - (this.getCurrentClicks() / (MAX_CLICKS / 2) * 255)})`;
    } else {
      return getColorForTemperature(this.getCurrentClicks(), MAX_CLICKS);
    }
  }
  renderEgg() {
    return (
      <div onClick={this.onClickEgg}>
        <Spritesheet
          className={`my-element__class--style`}
          image={`https://groxus-assets.s3.us-east-2.amazonaws.com/primordial/egg%20sprite%20sheet.png`}
          widthFrame={128}
          heightFrame={128}
          steps={24}
          fps={8}
          autoplay={true}
          loop={true}
        />
        {this.renderIdealBar()}
      </div>
    );
  }
  renderCreatureAlpha() {
    return (
      <Spritesheet
        className={`my-element__class--style`}
        image={`https://groxus-assets.s3.us-east-2.amazonaws.com/primordial/red%20monster%20sheet.png`}
        widthFrame={128}
        heightFrame={128}
        steps={24}
        fps={8}
        autoplay={true}
        loop={true}
      />
    );
  }
  renderCreatureBeta() {
    return (
      <Spritesheet
        className={`my-element__class--style`}
        image={`https://groxus-assets.s3.us-east-2.amazonaws.com/primordial/blue%20monster%20sheet.png`}
        widthFrame={128}
        heightFrame={128}
        steps={24}
        fps={8}
        autoplay={true}
        loop={true}
      />
    );
  }
  renderIdealBar() {
    const currentClicks = this.state.idealQuantity || 0;
    const goalClicks = IDEAL_TEMP_TICK_REQUIRE_COUNT;

    return (
      <PercentBar percentage={parseInt((currentClicks / goalClicks) * 100)} units={10}/>
    );
  }
  renderPhase() {
    const { data } = this.props;
    const phase = this.getCurrentPhase();
    if (!phase || phase === 'egg_1') {
      return (
        <div style={{ flex: 1, alignSelf: 'stretch', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: this.getTemperatureColor() }}>
          {this.renderEgg()}
          <div style={{ position: 'absolute', bottom: 0 }}>
            {this.isClickCountIdeal() && <div>It is ideal</div>}
            {this.isClickCountDeadly() && <div style={{ fontWeight: 'bold' }}>It is deadly</div>}
          </div>
        </div>
      );
    } else if (phase === 'creature_1') {
      return (
        <div style={{ flex: 1, alignSelf: 'stretch', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: this.getTemperatureColor() }}>
          {this.renderCreatureAlpha()}
          {this.renderEgg()}
        </div>
      );
    // } else if (phase === 'creature_2') {
    //   // const currentClicks = this.state.idealQuantity || 0;
    //   // const goalClicks = IDEAL_TEMP_TICK_REQUIRE_COUNT;
    //   // const distance = (currentClicks / goalClicks * 100).toFixed(2);
    //   const distance = (this.getCurrentClicks() / MAX_CLICKS) * 100;
    //   return (
    //     <div style={{ width: '100%', height: '100%' }} onClick={this.onClickEgg}>
    //       <div style={{ position: 'absolute', height: '50%', left: 0, right: 0, bottom: `${distance}%` }}>
    //         {this.renderCreatureAlpha()}
    //       </div>
    //       <div style={{ position: 'absolute', height: '50%', left: 0, right: 0, top: `${distance}%` }}>
    //         {this.renderCreatureBeta()}
    //       </div>
    //       <div style={{ padding: 40 }}>
    //         {this.renderIdealBar()}
    //         {this.getCurrentClicks()}
    //       </div>
    //     </div>
    //   );
    }
  }
  render() {
    const { style } = this.props;
    const { startingClicks } = this.state;

    return (
      <div style={style ? Object.assign({}, styles.container, style) : styles.container}>
        {this.renderPhase()}
      </div>
    );
  }
}

export default withMutastate(Primordial);

const styles = {
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
};
