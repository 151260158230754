import React from 'react';
import CloseIcon from '@material-ui/icons/Close';

export default function Modal({ children, onExit }) {
  return (
    <div style={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, zIndex: 50, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ position: 'absolute', left: 0, top: 0, bottom: 0, right: 0, zIndex: -1, backgroundColor: 'rgba(0,0,0,.6)' }} onClick={onExit}></div>
        <div style={{ maxHeight: '80%', width: '80%', maxWidth: 500, backgroundColor: '#fff', boxShadow: '0px 0px 10px #000', borderRadius: 10, display: 'flex', flexDirection: 'column' }}>
          <div style={{ paddingRight: 10, paddingTop: 10, display: 'flex', justifyContent: 'flex-end' }}>
            <CloseIcon style={{ cursor: 'pointer' }} onClick={onExit}/>
          </div>
          <div style={{ paddingLeft: 20, paddingRight: 20, paddingBottom: 20, flex: 1, overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
            {children}
          </div>
        </div>
      </div>
  );
}
