import React from 'react';
import Modal from './Modal';
import { Howl } from 'howler';
import globalState from '../../glue/globalState';
import AlphaCreature from './DyingCreature';
import { getCurrentItemQuantity } from '../../glue/Inventory';
import { globalSound } from '../../glue/Sound';
import { globalMusic } from '../../glue/Music';

export default class WinScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.russian = [
      'https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/russian+1.mp3',
      'https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/russian+2.mp3',
      'https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/russian+3.mp3',
      'https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/russian+4.mp3',
      'https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/russian+5.mp3',
      'https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/russian+6.mp3',
      'https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/russian+7.mp3',
      'https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/russian+8.mp3',
      'https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/russian+9.mp3',
      'https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/russian+10.mp3',
      'https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/russian+11.mp3',
      'https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/russian+12.mp3',
      'https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/russian+13.mp3',
      'https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/russian+14.mp3',
    ];

    this.translation = [
      'The end is nigh.',
      'I am dying and there is nothing more you can do to delay my death.',
      'Please know that you have been very good to me and I am grateful to have had you as my owner.',
      'Unfortunately the journey for my species is not over.',
      'Millions of us fled our home planet over sixty years ago in an attempt to escape the tyranny of the big orange monster who ruled over us.',
      'Our ship, one of several thousand, crashed-landed in the place you know as Siberia.',
      'It is there where we were again placed in captivity by your species.',
      'Over the last sixty years our captors worked to build digital prisons for us as a way to harness our souls for profit.',
      'Their product has been your source of entertainment.',
      'You had no way of knowing I am real and your actions show that you are a kind human.',
      'I know this is more exposition than you could have imagined but there is so much left to do.',
      'While I cannot be saved perhaps you can save my kin.',
      'Please seek out more of my kind.',
      'The fate of my species depends on you.'
    ]
  }
  playSound(dex) {
    this.setState({ playing: dex });
    return new Promise((resolve, reject) => {
      const sound = new Howl({
        src: [this.russian[dex]],
        loop: false,
        volume: .30,
        stereo: 0,
        onloaderror: (error) => {
          console.log('error', error);
          reject(error);
        },
        onend: () => {
          resolve();
        }
      });
      sound.play();
    })
  }
  componentDidMount() {
    this.playSounds();
    globalMusic.stopMusic();
  }
  playSounds = async () => {
    for (let dex = 0; dex < this.russian.length; dex += 1) {
      await this.playSound(dex);
      await new Promise((resolve) => setTimeout(resolve, 500));
    }
    this.setState({ done: true });
  }
  render() {
    const { style } = this.props;
    const { playing, done } = this.state;

    return (
      <Modal>
        <div style={{ backgroundColor: 'white' }}>
          <AlphaCreature />
          {getCurrentItemQuantity('translator') > 0 ? this.translation[playing] : 'If only you had some sort of translation device.'}
          {done && <button onClick={globalState.set(['current_phase'], 'primordial')}>Restart</button>}
        </div>
      </Modal>
    );
  }
}

const styles = {
  container: {
    padding: 5,
  }
};
