import React from "react";

const FoodIcon = props => (
  <svg viewBox={'0 0 38 38'} {...props}>
    <g stroke="currentColor" strokeWidth={2} fill="none" fillRule="evenodd">
      <path d="M28 1a9 9 0 0 1 2.646 17.605c.226 1.756.354 3.763.354 5.895 0 6.904 0 12.5-3 12.5-2.797 0-2.986-4.863-3-11.115V24.5c0-2.132.128-4.14.354-5.896A9 9 0 0 1 28 1z" />
      <path d="M10.592 1c.48.002.951.007 1.409.024L12 10h3V1.464c2.412.783 4 2.887 4 8.536 0 6.667-2.211 8.395-5.379 8.843.243 1.698.379 3.62.379 5.657 0 6.904 0 12.5-3.5 12.5-3.325 0-3.491-5.05-3.5-11.475V24.5c0-1.996.131-3.883.364-5.557C3.68 18.693 1 17.34 1 10c0-5.65 1.588-7.753 4-8.536V10h3V1.024c.457-.017.928-.022 1.408-.023z" />
    </g>
  </svg>
);

export default FoodIcon;
