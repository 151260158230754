import { Howl } from 'howler';
import globalState from './globalState';

export default class Music {
  playMusic = (iteration) => {
    const currentBatteryLevel = globalState.get(['danbiao', 'battery_percent']);

    let track1 = 'https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/loop.mp3';
    let track2 = 'https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/drums.mp3';
    let track3 = 'https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/lead.mp3';
    let track4 = 'https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/bass.mp3';

    if (currentBatteryLevel < 90  && currentBatteryLevel > 78 ) {
      track1 = 'https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/loop+2.mp3';
    } else if (currentBatteryLevel < 79  && currentBatteryLevel > 67 ) {
      track1 = 'https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/loop+2.mp3';
      track2 = 'https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/drums+2.mp3';
    } else if (currentBatteryLevel < 68  && currentBatteryLevel > 56 ) {
      track1 = 'https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/loop+2.mp3';
      track2 = 'https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/drums+2.mp3';
      track3 = 'https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/lead+2.mp3';
    } else if (currentBatteryLevel < 57  && currentBatteryLevel > 45 ) {
      track1 = 'https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/loop+2.mp3';
      track2 = 'https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/drums+2.mp3';
      track3 = 'https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/lead+2.mp3';
      track4 = 'https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/bass+2.mp3';
    } else if (currentBatteryLevel < 46  && currentBatteryLevel > 34 ) {
      track1 = 'https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/loop+3.mp3';
      track2 = 'https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/drums+2.mp3';
      track3 = 'https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/lead+2.mp3';
      track4 = 'https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/bass+2.mp3';
    } else if (currentBatteryLevel < 35  && currentBatteryLevel > 23 ) {
      track1 = 'https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/loop+3.mp3';
      track2 = 'https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/drums+3.mp3';
      track3 = 'https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/lead+2.mp3';
      track4 = 'https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/bass+2.mp3';
    } else if (currentBatteryLevel < 24  && currentBatteryLevel > 12 ) {
      track1 = 'https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/loop+3.mp3';
      track2 = 'https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/drums+3.mp3';
      track3 = 'https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/lead+3.mp3';
      track4 = 'https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/bass+2.mp3';
    } else if (currentBatteryLevel < 13  ) {
      track1 = 'https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/loop+3.mp3';
      track2 = 'https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/drums+3.mp3';
      track3 = 'https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/lead+3.mp3';
      track4 = 'https://groxus-assets.s3.us-east-2.amazonaws.com/danbiao/bass+3.mp3';
    }

    const tracks = [
      track1,
      track2,
      track3,
      track4,
    ];

    this.soundtracks = [];

    const promises = tracks.map((track, dex) => {
      return new Promise((resolve, reject) => {
        this.soundtracks.push(new Howl({
          src: [track],
          loop: false,
          volume: iteration < 1 ? 0 : .25,
          mute: globalState.get('muteMusic'),
          stereo: -.9,
          onloaderror: (error) => {
            console.log('error', error);
            reject(error);
          },
          onend: () => {
            resolve();
          }
        }));

        const result = this.soundtracks[this.soundtracks.length - 1].play();
      });
    });
    // Promise.all(promises).then();
    this.nextTimeout = setTimeout(() => this.playMusic(iteration + 1), 8000);
  }
  mute = () => {
    this.soundtracks.map(sound => {
      sound.mute(true);
      globalState.set('muteMusic', true);
    });
  }
  unmute = () => {
    this.soundtracks.map(sound => {
      sound.mute(false);
      globalState.set('muteMusic', false);
    });
  }
  toggleMute = () => {
    if (globalState.get('muteMusic')) this.unmute();
    else this.mute();
  }
  stopMusic = () => {
    if (this.playingSound) {
      clearTimeout(this.nextTimeout);
      this.soundtracks = [];
      this.playingSound = false;
    }
  }
  startMusic = () => {
    if (!this.playingSound) {
      this.playMusic(0);
      this.playingSound = true;
    }
  }
}

const globalMusic = new Music();

export { globalMusic };
