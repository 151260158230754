import React from "react";

const PlayIcon = props => (
  <svg viewBox={'0 0 28 36'} {...props}>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group" transform="translate(-1.924684, -0.862429)" stroke="currentColor" strokeWidth="2">
            <path d="M12.8878224,38 C15.2729987,38 14.4590812,29.9926016 15.7387957,19 C17.0185102,8.00739845 17.4422478,0 12.8878224,0 C8.33339698,0 8.11746968,7.10023176 10.502646,19 C12.8878224,30.8997682 10.502646,38 12.8878224,38 Z" id="Oval" transform="translate(12.800512, 19.000000) rotate(-330.000000) translate(-12.800512, -19.000000) "></path>
            <circle id="Oval" cx="24.9246843" cy="18.8624294" r="4"></circle>
        </g>
    </g>
  </svg>
);

export default PlayIcon;
